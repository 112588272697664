var validationTimeout;
var goButton;
var partTextfield;
jQuery('document').ready(function() {
    jQuery('.js-search-part-number').on('keyup', '.js-search-part-number-text', function(){
        if(jQuery(this).val().length > 2) {
            goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
            goButton.removeAttr('disabled');
            goButton.addClass('valid');
        } 
    }); 
    jQuery('.js-search-part-number').on('click', '.js-go-button', function(e) {
        e.preventDefault();
        textField = jQuery(this).closest('.js-search-part-number').find('.js-search-part-number-text');
        partTextfield = textField.val();
        url = textField.data('url'); 
        lang = jQuery('html').attr('lang');
        form = $(this.form);
        $.ajax({
            url: url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                partNumber : partTextfield
            },
            success: function(data) {
                if(data.valid == 1) {
                    form.attr('action', form.attr('action').replace('$1', encodeURIComponent(partTextfield)));
                    form.submit();
                } else {

                    textField.val('');
                    textField.blur(); 
                    if(lang == 'en') {
                        textField.attr("placeholder", "No result found.");
                    } else {
                        textField.attr("placeholder", "Aucun résultat trouvé.");
                    }
                }
            },
            error: function() {
                textField.val('');
                textField.blur();
                if(lang == 'en') {
                    textField.attr("placeholder", "No result found.");
                } else {
                    textField.attr("placeholder", "Aucun résultat trouvé.");
                }

            }
        });
    });
});